import React from 'react';
import Badge from '@mui/material/Badge';
import clsx from 'clsx';
import sum from 'lodash/sum';
import { NotificationIcon } from 'pages/Dashboard/assets/icons';
import useDosespotNotifications from 'pages/Dashboard/hooks/useDosespotNotifications';

type Props = {
  className?: string;
  data?: { expanded?: boolean };
}

export default function NotificationIndicator({
  className,
  data,
}: Props) {
  const isExpanded = data?.expanded ?? false;
  const { data: counts } = useDosespotNotifications();
  const count = sum([
    counts?.pendingRxChangeCount ?? 0,
    counts?.transactionErrorsCount ?? 0,
    counts?.pendingPrescriptionsCount ?? 0,
    counts?.refillRequestsCount ?? 0,
  ]);

  return (
    <Badge
      badgeContent={count}
      color="error"
      invisible={isExpanded}
      className="flex items-center"
      sx={{
        '.MuiBadge-badge': {
          fontSize: '0.625rem',
          minWidth: '0.875rem !important',
          height: '0.875rem !important',
          paddingLeft: '.25rem',
          paddingRight: '.25rem',
        },
      }}
    >
      <NotificationIcon className={className} />
      <span className={clsx('text-xs ml-2 mr-12', { hidden: !isExpanded })}>
        Notifications
      </span>
      <Badge
        badgeContent={count}
        color="error"
        invisible={!isExpanded}
        sx={{
          '.MuiBadge-badge': {
            fontSize: '0.75rem',
            minWidth: '1.25rem !important',
            height: '1.25rem !important',
          },
        }}
      />
    </Badge>
  );
}
