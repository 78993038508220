import React from 'react';
import Spinner from 'pages/Dashboard/components/Spinner';

export default function FullScreenSpinner() {
  return (
    <div className="w-screen h-screen flex items-center justify-center overflow-hidden">
      <Spinner size={32} />
    </div>
  );
}
