import { LoggedInUserInfoDTO } from 'dtos/loggedInUserInfoDTO';
import { getLoggedUserInfo } from 'pages/Dashboard/services/api';
import usePrefetchedData from 'pages/Dashboard/hooks/usePrefetchedData';

export default function useUserInfo(): LoggedInUserInfoDTO | null {
  const { data } = usePrefetchedData<LoggedInUserInfoDTO>({
    key: 'logged-user-info',
    fetchFn: getLoggedUserInfo,
  });

  return data ?? null;
}
