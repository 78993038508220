import React, { useEffect } from 'react';
import MuiAlert from '@mui/material/Alert';

export enum AlertVariant {
  SUCCESS = 'success',
  INFO = 'info',
  ERROR = 'error',
  WARNING = 'warning',
}

interface Props {
  className?: string;
  open?: boolean;
  dismissible?: boolean;
  onClose?: () => void;
  timeoutInMs?: number;
  variant?: 'standard' | 'filled' | 'outlined';
  severity?: AlertVariant;
  children?: string | React.ReactNode;
}

function Alert({
  className,
  variant = 'standard',
  severity,
  children,
  open,
  timeoutInMs = 10 * 1000,
  dismissible,
  onClose = () => {},
}: Props) {
  useEffect(() => {
    if (!dismissible) return undefined;
    let timeoutId: ReturnType<typeof setTimeout>;
    if (open) {
      timeoutId = setTimeout(() => onClose(), timeoutInMs);
    }

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [open, timeoutInMs, dismissible]);

  return open ? (
    <MuiAlert
      className={className}
      variant={variant}
      severity={severity}
      onClose={dismissible ? onClose : undefined}
    >
      {children}
    </MuiAlert>
  ) : null;
}

export default Alert;
