import React, {
  useCallback,
  useContext, useEffect,
} from 'react';
import { unstable_useBlocker as useBlocker } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import noop from 'lodash/noop';
import mixpanel from 'mixpanel-browser';
import { auth0ClientId, environment } from 'env';
import { ConfirmationDialogContext } from 'pages/Dashboard/contexts/confirmationDialog';
import { NotificationContext } from 'pages/Dashboard/contexts/notification';
import useUserInfo from 'pages/Dashboard/hooks/useUserInfo';
import { UserSettingsContext } from 'pages/Dashboard/contexts/userSettings';
import { SidePanelContext } from 'pages/Dashboard/contexts/sidePanel';
import {
  generateTokenItem,
  getAccessTokenFromLocalStorage,
  getIsTokenItemValid,
  setAccessTokenToLocalStorage,
  setHeaders,
} from 'utils/auth';
import { ExpandableContext } from 'core/components/ExpandableContext';

// @TODO
// If file will exceed 300 lines code,
// let's split it into multiple files under hooks directory,
// where hooks will be grouped together logically

export function useNotify() {
  const { notify } = useContext(NotificationContext);
  return notify;
}

export function useExpandContext() {
  return useContext(ExpandableContext);
}

export function useConfirmDialog() {
  const { openDialog, closeDialog } = useContext(ConfirmationDialogContext);
  return {
    confirm: openDialog,
    closeConfirm: closeDialog,
  };
}

export function usePrompt(when: boolean, message?: string) {
  const blocker = useBlocker(when);
  const { confirm, closeConfirm } = useConfirmDialog();

  useEffect(() => {
    if (blocker?.state === 'blocked') {
      confirm({
        className: '!w-[32rem]',
        disableBackdropClick: true,
        hideCloseBtn: true,
        title: 'Leave Page?',
        content: (
          <p className="!mt-0">
            {message ?? 'You have unsaved changes. Do you want to leave without saving?'}
          </p>
        ),
        confirmText: 'Stay on Page',
        cancelText: 'Leave Page',
        onConfirm: () => blocker.reset?.(),
        onClose: () => {
          blocker.proceed?.();
          closeConfirm();
        },
      });
    } else {
      closeConfirm();
    }
  }, [blocker]);
}

export function useMixpanelTrack() {
  if (environment !== 'prod') return noop;

  const userInfo = useUserInfo();
  const { user, organization } = userInfo ?? {};

  return (eventName: string, properties?: Record<string, any>) => {
    mixpanel.track(eventName, {
      ...(properties ?? {}),
      userTitle: user?.title,
      orgId: organization?.organizationId,
    });
  };
}

export function useUserSettings() {
  const userSettings = useContext(UserSettingsContext);
  return userSettings;
}

export function useSidePanel() {
  const { openSidePanel, closeSidePanel } = useContext(SidePanelContext);
  return { openSidePanel, closeSidePanel };
}

export function useLogoutFromApp() {
  const { logout } = useAuth0();
  return async () => {
    await logout({
      clientId: auth0ClientId,
      logoutParams: {
        federated: true,
        returnTo: window.location.origin,
      },
    });

    setHeaders('');
    localStorage.clear();
    sessionStorage.clear();
  };
}

export function useAuth() {
  const {
    isAuthenticated,
    getAccessTokenSilently,
    isLoading,
    loginWithRedirect,
    handleRedirectCallback,
  } = useAuth0();
  const logout = useLogoutFromApp();

  const issueAccessToken = useCallback(async (): Promise<string | null> => {
    const cachedTokenItem = getAccessTokenFromLocalStorage();
    const cachedTokenIsExpired = !getIsTokenItemValid(cachedTokenItem) && isAuthenticated;

    try {
      const token = await (cachedTokenIsExpired
        ? getAccessTokenSilently()
        : Promise.resolve(cachedTokenItem.token)
      );

      if (cachedTokenIsExpired) {
        setAccessTokenToLocalStorage(generateTokenItem(token));
      }

      setHeaders(token);
      return token;
    } catch (e) {
      console.error(e);
      logout();
    }

    return null;
  }, [isAuthenticated, getAccessTokenSilently, loginWithRedirect]);

  return {
    issueAccessToken,
    isAuthenticated,
    isLoading,
    logout,
    loginWithRedirect,
    handleRedirectCallback,
  };
}
