import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';

export default function BillingIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      {...props}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.21094 11.592C4.30827 13.0493 6.04627 14 8.00027 14C11.3003 14 14.0003 11.3 14.0003 8C14.0003 4.7 11.3003 2 8.00027 2"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M3.21067 11.592C2.45467 10.588 2 9.346 2 8C2 4.7 4.7 2 8 2"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="0"
        fill="none"
      />
      <path
        d="M7.83333 4.66675V5.50008"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M7.83333 11.3333V10.5"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M9.49166 6.66667C9.44833 6.016 8.91166 5.5 8.25033 5.5H7.33699C6.69033 5.5 6.16699 6.024 6.16699 6.67C6.16699 7.20667 6.53233 7.67467 7.05233 7.80533L8.61433 8.19733C9.13499 8.328 9.49966 8.796 9.49966 9.33267C9.49966 9.97933 8.97566 10.5027 8.32966 10.5027H7.41633C6.75366 10.5027 6.21699 9.98533 6.17433 9.33333"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
    </SvgIcon>
  );
}
