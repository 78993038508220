import React from 'react';
import { Auth0Provider } from '@auth0/auth0-react';
import { ThemeProvider } from '@mui/material/styles';
import { auth0ProviderConfig } from 'config/auth';
import { theme } from 'config/mui';
import 'config/sentry';
import 'config/mixpanel';
import NotificationProvider from 'pages/Dashboard/contexts/notification';
import ErrorBoundary from 'pages/Dashboard/components/ErrorBoundary';
import ReactQueryProvider from 'core/components/ReactQueryProvider';
import { RouterProvider } from 'react-router-dom';
import { router } from 'router';

function App() {
  return (
    <ErrorBoundary>
      <Auth0Provider {...auth0ProviderConfig}>
        <NotificationProvider>
          <ReactQueryProvider>
            <ThemeProvider theme={theme}>
              <div className="h-full w-full">
                <RouterProvider router={router} />
              </div>
            </ThemeProvider>
          </ReactQueryProvider>
        </NotificationProvider>
      </Auth0Provider>
    </ErrorBoundary>
  );
}

export default App;
