import { getFullName } from 'utils/string';
import { formatDateTime, formatDate } from 'utils/date';
import { UserCompact, UserLike, User } from 'types/user';
import {
  RawToDo,
  ToDo,
  ToDoStatus,
  CommentActivity,
} from 'types/toDo';
import {
  ToDosResponse,
  CommentActivityResponse,
} from 'pages/Dashboard/pages/Todos/types';
import { Patient } from 'pages/Dashboard/types/patient';

const statusLabels: Record<string, string> = Object.freeze({
  Unstarted: 'Not Started',
  InProgress: 'In Progress',
  Completed: 'Completed',
  Abandon: 'Abandoned',
  Abandoned: 'Abandoned',
});

const transitions: Record<string, string> = Object.freeze({
  Unstarted: 'Unstarted',
  Completed: 'Completed',
  InProgress: 'InProgress',
  Abandoned: 'Abandon',
});

export function normalizeCurrentToDoStatus(status: string): ToDoStatus {
  return ({
    transitionName: transitions[status],
    statusDisplayName: statusLabels[status],
  });
}

export function normalizeValidToDoStatuses(statuses: string[]): ToDoStatus[] {
  return statuses.map((status) => ({
    transitionName: transitions[status],
    statusDisplayName: statusLabels[status],
  }));
}

export function normalizeAvailableToDoStatuses(
  currentStatus: string,
  availableStatuses: ToDoStatus[],
): ToDoStatus[] {
  return [normalizeCurrentToDoStatus(currentStatus)]
    .concat(
      availableStatuses
        .filter((status) => status.transitionName !== 'Abandon')
        .map((status) => ({
          transitionName: status.transitionName,
          statusDisplayName: statusLabels[status.statusDisplayName],
        })),
    );
}

function normalizeUser(user: UserLike): UserCompact {
  return {
    id: user?.userId ?? user?.patientId ?? 0,
    fullName: user ? getFullName(user) : 'Developer',
    image: null,
  };
}

function normalizePatient(patient: Patient): Patient {
  return {
    ...patient,
    patientId: patient?.patientId ?? 0,
    fullName: patient ? getFullName(patient) : 'Developer',
  };
}

export function normalizeCommentActivity(
  commentsResponse: CommentActivityResponse,
): CommentActivity[] {
  const { commentActivity: data, users } = commentsResponse;
  return (data ?? [])
    .map((comment: CommentActivity) => ({
      ...comment,
      creator: normalizeUser(users[comment.creatorUserId]),
      formattedDate: formatDateTime(comment.createdOn),
    }));
}

export function normalizeToDo(
  data: RawToDo,
  userDict?: Record<number, User>,
  patientsDict?: Record<number, Patient>,
): ToDo {
  return {
    ...data,
    currentStatus: normalizeCurrentToDoStatus(data.statusDisplayName),
    availableStatuses: normalizeAvailableToDoStatuses(
      data.statusDisplayName,
      data.applicableStatusTransitions,
    ),
    isAbandoned: data.statusDisplayName === 'Abandoned',
    formattedDate: formatDate(data.createdOn),
    ...(
      typeof userDict !== 'undefined'
        ? ({
          assignee: normalizeUser(userDict[data.assigneeUserId]),
          creator: normalizeUser(userDict[data.creatorUserId]),
        }) : {}
    ),
    ...(
      typeof patientsDict !== 'undefined'
        ? ({
          patient: normalizePatient(patientsDict[data.patientId]),
        }) : {}
    ),
  };
}

export function normalizeToDos(response: ToDosResponse): ToDo[] {
  const patientsDict = response.patients;
  const usersDict = response.users;

  return response.toDos.map(
    (toDo: RawToDo) => (
      normalizeToDo(
        toDo,
        usersDict,
        patientsDict,
      )
    ),
  );
}
