import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';

export default function TaskIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      {...props}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.42105 9.22222L9.63158 11.5556L17 3.77778M16.2632 10V15.4444C16.2632 15.857 16.1079 16.2527 15.8315 16.5444C15.5552 16.8361 15.1803 17 14.7895 17H4.47368C4.08284 17 3.708 16.8361 3.43163 16.5444C3.15526 16.2527 3 15.857 3 15.4444V4.55556C3 4.143 3.15526 3.74733 3.43163 3.45561C3.708 3.16389 4.08284 3 4.47368 3H12.5789"
        fill="none"
        stroke="currentColor"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
}
