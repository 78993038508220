import type { ReactNode } from 'react';
import React, { createContext, useMemo, useState } from 'react';
import Alert, { AlertVariant } from 'pages/Dashboard/components/Alert';
import { CommonComponentPropType } from 'pages/Dashboard/types/common';

export type NotificationProps = {
  message: string | ReactNode;
  variant?: AlertVariant;
  timeoutInMS?: number;
  dismissible?: boolean;
  onClose?: () => void;
};

type ContextValue = {
  notify: (props: NotificationProps) => void;
};

export const NotificationContext = createContext<ContextValue>({
  notify: () => {},
});

function NotificationProvider({ children }: CommonComponentPropType) {
  const [show, setShow] = useState<boolean>(false);
  const DEFAULT_PROPS: NotificationProps = {
    variant: AlertVariant.INFO,
    message: 'try this notification',
    timeoutInMS: 10 * 1000,
    dismissible: true,
    onClose: () => setShow(false),
  };
  const [notificationProps, setNotificationProps] = useState<NotificationProps>(DEFAULT_PROPS);

  const notify = (props: NotificationProps) => {
    const onClose = () => {
      setShow(false);
      if (props.onClose) {
        props.onClose();
      }
    };
    setNotificationProps({
      ...DEFAULT_PROPS,
      ...props,
      onClose,
    });
    setShow(true);
  };

  const values = useMemo(() => ({ notify }), [notify]);

  return (
    <NotificationContext.Provider value={values}>
      <Alert
        className="fixed top-0 left-[50%] -translate-x-1/2 z-[99999] shadow-2xl"
        open={show}
        severity={notificationProps.variant}
        dismissible={notificationProps.dismissible}
        timeoutInMs={notificationProps.timeoutInMS}
        onClose={notificationProps.onClose}
      >
        {notificationProps.message}
      </Alert>
      {children}
    </NotificationContext.Provider>
  );
}

export default NotificationProvider;
