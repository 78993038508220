import React, {
  createContext,
  useState,
  useMemo,
  useEffect,
  ReactNode,
  ComponentType,
} from 'react';
import { useLocation } from 'react-router-dom';
import Drawer from '@mui/material/Drawer';
import isNil from 'lodash/isNil';

export type SidePanelComponentProps = {
  entityId: number;
  onClose?: () => void;
};

type SidePanelProps = {
  entityId: number;
  Component: ComponentType<SidePanelComponentProps> | null;
};

type ContextValues = {
  openSidePanel: (props: SidePanelProps) => void;
  closeSidePanel: () => void;
};

type ProviderProps = {
  children: ReactNode;
}

export const SidePanelContext = createContext<ContextValues>({
  openSidePanel: () => {},
  closeSidePanel: () => {},
});

export default function DetailedViewContextProvider({ children }: ProviderProps) {
  const [props, setProps] = useState<SidePanelProps>({
    entityId: 0,
    Component: null,
  });
  const [open, setOpen] = useState<boolean>(false);
  const location = useLocation();
  const openSidePanel = (_props: SidePanelProps) => {
    setProps(_props);
    setOpen(true);
  };

  const closeSidePanel = () => { setOpen(false); };

  const values = useMemo(() => ({
    openSidePanel,
    closeSidePanel,
  }), [openSidePanel, closeSidePanel]);

  useEffect(() => {
    if (!isNil(props.Component)) {
      closeSidePanel();
      setProps({ entityId: 0, Component: null });
    }
  }, [location.pathname]);

  const { Component, entityId } = props;

  return (
    <SidePanelContext.Provider value={values}>
      <Drawer
        anchor="right"
        open={open}
        onClose={closeSidePanel}
        hideBackdrop
        style={{ height: 'calc(100%-0.875rem)!important' }}
        classes={{ root: 'detailed-entity-drawer' }}
        disableEnforceFocus
        disableScrollLock
        disableAutoFocus
        disableRestoreFocus
      >
        {!isNil(Component) && (
          <Component
            entityId={entityId}
            onClose={closeSidePanel}
          />
        )}
      </Drawer>
      {children}
    </SidePanelContext.Provider>
  );
}
