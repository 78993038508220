import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';

export default function FolderIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      {...props}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.0029 6.93789H12.5292C12.1968 6.93806 11.886 6.77311 11.6998 6.4977L10.2993 4.43385C10.1124 4.15959 9.80176 3.99582 9.46991 3.99666H4.99705C3.89202 3.99666 2.99622 4.89247 2.99622 5.99749V18.0025C2.99622 19.1075 3.89202 20.0033 4.99705 20.0033H19.0029C20.1079 20.0033 21.0037 19.1075 21.0037 18.0025V8.93872C21.0037 7.83369 20.1079 6.93789 19.0029 6.93789Z"
        stroke="currentColor"
        strokeWidth="1.5"
        fill="none"
      />
      <path
        d="M2.99622 9.87911H9.46991C9.8007 9.87896 10.11 9.7153 10.2963 9.44192L11.6968 7.37606C11.883 7.10201 12.1929 6.93793 12.5242 6.93788"
        stroke="currentColor"
        strokeWidth="1.5"
        fill="none"
      />
    </SvgIcon>

  );
}
