import React, {
  useEffect,
} from 'react';
import FullScreenSpinner from 'core/components/FullScreenSpinner';
import { useAuth } from 'core/hooks';

export default function Auth() {
  const { loginWithRedirect } = useAuth();

  useEffect(() => {
    const targetUrl = `${window.location.pathname}${window.location.search}`;
    loginWithRedirect({ appState: { targetUrl } });
  }, []);

  return <FullScreenSpinner />;
}
