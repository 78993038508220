import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { apiUrl } from 'env';

axios.defaults.baseURL = apiUrl;

const request = async <T>(
  url: string,
  config: AxiosRequestConfig,
): Promise<T> => axios.request<T>({
  url,
  ...config,
}).then((res: AxiosResponse) => res.data);

export { request };
