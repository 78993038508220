import React, {
  createContext,
  useMemo,
  useState,
  useEffect,
} from 'react';
import isEmpty from 'lodash/isEmpty';
import { useGetUserSettings } from 'pages/Dashboard/hooks/useUserSettings';
import { CommonComponentPropType } from 'pages/Dashboard/types/common';
import FullScreenSpinner from 'core/components/FullScreenSpinner';
import { SettingsDTO, UserSettingDTO } from 'dtos';
import usePrefetchedData from 'pages/Dashboard/hooks/usePrefetchedData';
import { getEncounterSettings } from 'pages/Dashboard/pages/Encounters/transport';
import { getToDoSettings } from 'pages/Dashboard/pages/Todos/transport';
import { ToDoSettingsResponse } from 'pages/Dashboard/pages/Todos/types';
import { getUserSettings } from 'pages/Dashboard/services/api';

type Props = {
  settings: Record<string, any>;
  setSettings: (settings: Record<string, any>) => void;
  retrievingSettings: boolean;
};

export const UserSettingsContext = createContext<Props>({
  settings: {},
  setSettings: () => {},
  retrievingSettings: false,
});

export default function UserSettingsProvider({ children }: CommonComponentPropType) {
  const { data, isLoading: loadingUserSettings } = useGetUserSettings();
  // temporary prefetching is here
  const { isLoading: loadingUserInfo } = usePrefetchedData<UserSettingDTO>({
    key: 'user-view-settings',
    fetchFn: getUserSettings,
  });
  const { isLoading: loadingEncounterMetadata } = usePrefetchedData<SettingsDTO>({
    key: 'appointment-settings',
    fetchFn: getEncounterSettings,
  });
  const { isLoading: loadingToDoMetadata } = usePrefetchedData<ToDoSettingsResponse>({
    key: 'to-do-settings',
    fetchFn: getToDoSettings,
  });

  const loadingList = [
    loadingUserSettings,
    loadingEncounterMetadata,
    loadingToDoMetadata,
    loadingUserInfo,
  ];

  const loading = useMemo(() => (
    loadingList.every((isLoading) => isLoading)
  ), loadingList);

  const [settings, setSettings] = useState<Record<string, any>>({});
  const values = useMemo(() => ({
    settings,
    setSettings,
    retrievingSettings: loading,
  }), [settings, setSettings, loading]);

  useEffect(() => {
    if (!isEmpty(data) && !loadingUserSettings) {
      setSettings(JSON.parse(data?.preferenceJson ?? '{}'));
    }
  }, [data, loadingUserSettings]);

  return (
    <UserSettingsContext.Provider value={values}>
      {loading ? <FullScreenSpinner /> : children}
    </UserSettingsContext.Provider>
  );
}
