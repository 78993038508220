import compact from 'lodash/compact';
import isNil from 'lodash/isNil';
import values from 'lodash/values';
import { AddressDTO } from 'dtos/addressDTO';
import { isEmptyString } from 'utils/misc';

export interface IUser {
  firstName?: string | null;
  middleName?: string | null;
  lastName?: string | null;
  fullName?: string;
}

export function getFullName({
  firstName = '',
  lastName = '',
  middleName = '',
  fullName,
}: IUser): string {
  return !isEmptyString(fullName)
    ? fullName ?? '--'
    : compact([firstName, middleName, lastName]).join(' ');
}

export function getUserInitials(fullName: string = ''): string | null {
  const parts = fullName.split(' ');
  const result = parts.length >= 3
    ? [parts[0][0], parts[2][0]].join('')
    : compact(parts).map((part) => part[0]).join('');

  return result === '' ? null : result.toUpperCase();
}

export function getAddressString(address?: AddressDTO, includeCountry = false) {
  const {
    street = '',
    unit = '',
    city = '',
    state = '',
    zipCode = '',
  } = address ?? {};
  const cityStateZip = compact([
    city,
    state,
    zipCode,
    includeCountry ? 'USA' : null,
  ]).join(', ');
  return !isNil(address) && !values(address).every(isEmptyString)
    ? `${compact([street, unit, cityStateZip]).join(' ')}`
    : '--';
}

export function pluralize(
  word: string,
  count: number = 0,
  showCount: boolean = true,
) {
  return `${showCount ? `${count} ` : ''}${word}${count === 1 ? '' : 's'}`;
}

export const formatMoney = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format;
export const DEFAULT_REVIEW_OF_SYSTEM_VALUE = 'N/A';
export const getDefaultReviewOfSystemRegex = (
  includePatientDenies?: boolean,
): RegExp => new RegExp(
  `^(${DEFAULT_REVIEW_OF_SYSTEM_VALUE}${includePatientDenies ? '|Patient Denies' : ''})$`,
  'i',
);
const PHONE_REGEX = /^(\+1)?(\d{3})(\d{3})(\d{4})$/i;

export function formatPhone(phone: string) {
  const [match, , areaCode, start, end] = phone.match(PHONE_REGEX) ?? [];
  return isNil(match) ? '--' : `(${areaCode}) ${start}-${end}`;
}
