import { request } from 'services/api';
import { SettingsDTO } from 'dtos';

export const getEncounterSettings = ():
  Promise<SettingsDTO> => (
  request<SettingsDTO>(
    'Settings/GetAppointmentSettings',
    { method: 'GET' },
  )
);
