import { request } from 'services/api';
import {
  ToDo,
  CommentActivity,
} from 'types/toDo';
import {
  CommentResponsePayload,
  UpdateToDoRequestPayload,
  UpdateToDoResponsePayload,
  ToDosResponse,
  CommentActivityResponse,
  ToDoResponse,
  ToDoSettingsResponse,
} from 'pages/Dashboard/pages/Todos/types';
import {
  normalizeToDos,
  normalizeToDo,
  normalizeCommentActivity,
} from 'pages/Dashboard/pages/Todos/normalize';

export const createToDo = (data: Partial<ToDo>): Promise<ToDo> => request<ToDo>('ToDo/CreateToDo', {
  method: 'POST',
  data,
});

export const commentOnToDo = (data: Partial<ToDo>): Promise<CommentResponsePayload> => (
  request<CommentResponsePayload>('ToDo/CommentOnToDo', {
    method: 'POST',
    data,
  })
);

export const getToDos = (
  from?: string,
  to?: string,
  include: boolean = false,
): Promise<ToDo[]> => (
  request<ToDosResponse>(
    'ToDo/FilterByDateRange',
    {
      method: 'GET',
      params: {
        includeReferencedObjects: include,
        fromDate: encodeURI(from ?? ''),
        toDate: encodeURI(to ?? ''),
      },
    },
  ).then(normalizeToDos)
);

export const getToDosByPatientId = (
  patientId: number,
  include: boolean = false,
): Promise<ToDo[]> => (
  request<ToDosResponse>(
    `ToDo/GetAllToDosByPatientId?patientId=${patientId}&includeReferencedObjects=${include.toString()}`,
    { method: 'GET' },
  ).then(normalizeToDos)
);

export const getDetailedToDo = (toDoId: number): Promise<ToDo> => (
  request<ToDoResponse>(`ToDo/GetToDoById/?toDoId=${toDoId}&includeReferencedObjects=true`, { method: 'GET' }).then(
    ({ users, patients, ...response }: ToDoResponse) => (
      normalizeToDo(response, users, patients)
    ),
  )
);

export const getToDoComments = (toDoId: number): Promise<CommentActivity[]> => (
  request<CommentActivityResponse>(`ToDo/GetCommentsByToDoId/?toDoId=${toDoId}&includeReferencedObjects=true`, { method: 'GET' })
    .then(normalizeCommentActivity)
);

export const getToDoSettings = (): Promise<ToDoSettingsResponse> => (
  request<ToDoSettingsResponse>(
    'Settings/GetToDoSettings',
    { method: 'GET' },
  )
);

export const updateToDo = (data: UpdateToDoRequestPayload): Promise<UpdateToDoResponsePayload> => (
  request<UpdateToDoResponsePayload>('ToDo/UpdateToDo', {
    method: 'PUT',
    data,
  })
);
