import React, {
  ReactNode,
  RefObject,
} from 'react';
import clsx from 'clsx';
import isNil from 'lodash/isNil';
import isEmpty from 'lodash/isEmpty';
import MuiDialog from '@mui/material/Dialog';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { Breakpoint } from '@mui/material/styles';
import Button from 'pages/Dashboard/components/Button';

type Props = {
  className?: string;
  dialogClassName?: string;
  closeBtnClasses?: string;
  title?: string | ReactNode;
  // @TODO: move all className props to classes
  titleClassName?: string;
  open: boolean;
  onClose: () => void;
  onConfirm?: () => void;
  confirmText?: string;
  cancelText?: string;
  disableBackdropClick?: boolean;
  disableConfirmButton?: boolean;
  disableEscapeKeyDown?: boolean;
  showActions?: boolean;
  hideCloseBtn?: boolean;
  content?: ReactNode;
  children?: ReactNode;
  maxWidth?: false | Breakpoint | undefined;
  fullWidth?: boolean;
  dialogRef?: RefObject<HTMLDivElement>;
  minHeight?: number | string;
};

export default function Dialog({
  className,
  dialogClassName,
  closeBtnClasses,
  titleClassName,
  title,
  open,
  onClose,
  onConfirm = () => {
  },
  disableBackdropClick,
  disableConfirmButton,
  disableEscapeKeyDown = false,
  confirmText = 'Proceed',
  cancelText = 'Cancel',
  content,
  children,
  showActions,
  hideCloseBtn,
  maxWidth = 'xs',
  fullWidth = false,
  dialogRef,
  minHeight: _minHeight,
}: Props) {
  const classes = clsx('p-5 flex flex-col space-y-5 w-[24rem] relative text-sm', className);
  const dialogClasses = clsx('populate-dialog', dialogClassName);
  const header = typeof title === 'string'
    ? <h2 className="text-lg font-semibold mb-3">{title}</h2>
    : title;
  const minHeight = typeof _minHeight === 'number' ? `${_minHeight}px` : _minHeight;

  return (
    <MuiDialog
      className={dialogClasses}
      open={open}
      onClose={(event, reason) => {
        if (reason === 'backdropClick' && disableBackdropClick) return;
        onClose();
      }}
      classes={{ paper: '!overflow-y-visible' }}
      disableEscapeKeyDown={disableEscapeKeyDown}
      maxWidth={maxWidth}
      fullWidth={fullWidth}
    >
      <div
        className={classes}
        ref={dialogRef}
        style={!isNil(minHeight) ? { minHeight } : undefined}
      >
        {(!isEmpty(title) || !hideCloseBtn) && (
          <div
            className={clsx('flex flex-row justify-between', titleClassName)}
          >
            {header}
            {!hideCloseBtn && (
              <IconButton
                className={clsx('relative -top-3 -right-3 !w-8 !h-8', closeBtnClasses)}
                onClick={onClose}
              >
                <CloseIcon />
              </IconButton>
            )}
          </div>
        )}
        {children ?? content}
        {showActions && (
          <div className="flex justify-end items-center space-x-2">
            <Button
              variant="outlined"
              type="button"
              onClick={onClose}
            >
              {cancelText}
            </Button>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              onClick={onConfirm}
              disabled={disableConfirmButton}
            >
              {confirmText ?? 'Proceed'}
            </Button>
          </div>
        )}
      </div>
    </MuiDialog>
  );
}
