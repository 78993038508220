import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from 'core/hooks';

export default function AuthorizeScreen() {
  const navigate = useNavigate();
  const { handleRedirectCallback } = useAuth();

  useEffect(() => {
    (async () => {
      try {
        const state = await handleRedirectCallback();
        navigate(state.appState.targetUrl ?? window.location.origin);
      } catch (error) {
        // If query params is not exist, then navigate to the origin
        navigate('/');
      }
    })();
  }, []);

  return null;
}
