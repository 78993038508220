import compact from 'lodash/compact';
import map from 'lodash/map';
import { request } from 'services/api';
import { LoggedInUserInfoDTO } from 'dtos/loggedInUserInfoDTO';
import {
  normalizeDoctors,
  withProfileImages,
} from 'pages/Dashboard/utils/helper';
import {
  MultiMediaDeleteDTO,
  MultiMediaRequestDTO,
  MultiMediaResponseDTO,
  MultiMediaResponse,
  StickyDTO,
  UserSettingDTO,
  PatientDTO,
  VisitNotePageViewModel,
  EligibilityRequestDTO,
  EligibilityResponseDTO,
  PayerDTO,
} from 'dtos';
import {
  Doctor,
  NormalizedUser,
} from 'pages/Dashboard/pages/Appointments/pages/List/types/event';
import { formatPatient } from 'pages/Dashboard/pages/Charts/pages/List/utils/helper';
import { DosespotNotifications } from 'pages/Dashboard/types/dosespot';
import {
  UploadMedia,
} from 'pages/Dashboard/types/multimedia';
import { Patient } from 'pages/Dashboard/types/patient';

function normalizeMultiMediaResponseDTO(dto: MultiMediaResponseDTO) {
  return dto?.list ?? [];
}

export const getPatients = async (searchText?: string): Promise<Patient[]> => {
  const _patients = await request<PatientDTO[]>(
    '/patient/searchPatients',
    {
      method: 'GET',
      params: { searchText },
    },
  );

  const patients = await withProfileImages(_patients.map(formatPatient)) as Patient[];

  return patients;
};

export const searchVisitNotesByPatientName = (
  searchText?: string,
): Promise<VisitNotePageViewModel[]> => (
  request<VisitNotePageViewModel[]>(
    '/visitNote/getVisitNotesByPatientName',
    {
      method: 'GET',
      params: { searchText },
    },
  )
);

export const uploadMultiMedia = (data: UploadMedia): Promise<MultiMediaResponse> => (
  request<MultiMediaResponse>('multimedia/postPatientMultiMedia', { method: 'POST', data })
);

export const deleteMultiMedia = (data: MultiMediaDeleteDTO): Promise<MultiMediaResponse> => (
  request<MultiMediaResponse>('/multimedia/deleteMultiMedia', { method: 'POST', data })
);

export const getMultiMedia = (data: MultiMediaRequestDTO): Promise<MultiMediaResponse[]> => (
  request<MultiMediaResponseDTO>(
    '/multimedia/requestMultiMedia',
    { method: 'POST', data },
  ).then(normalizeMultiMediaResponseDTO)
);

export const getDosespotNotifications = (): Promise<DosespotNotifications> => (
  request<DosespotNotifications>('/eRx/UserNotificationCounts', {
    method: 'GET',
  })
);

export const getLoggedUserInfo = (): Promise<LoggedInUserInfoDTO> => (
  request<LoggedInUserInfoDTO>('/User/GetLoggedInUserInfo', { method: 'GET' })
);

export const getDoctors = (onlyDoctors = true): Promise<NormalizedUser[]> => (
  request<Doctor[]>(`User/GetAllUsersByTitle${onlyDoctors ? '/doctor' : ''}`, { method: 'GET' })
    .then(normalizeDoctors)
);

export function geteRxPage(patientId?:string): Promise<string> {
  const hasPatient = typeof patientId !== 'undefined';
  const url = `eRx/${hasPatient ? 'PatientWebpageURL' : 'UserWebpageURL'}`;
  return request<{ url: string }>(url, {
    method: 'GET',
    params: { patientId },
  }).then((data) => data.url);
}

export function getUserSettings(): Promise<UserSettingDTO> {
  return request<UserSettingDTO>('Settings/GetOrCreateUserSettings', { method: 'GET' });
}

export function upsertUserSettings(data: UserSettingDTO)
  : Promise<UserSettingDTO> {
  return request<UserSettingDTO>('Settings/CreateOrUpdateUserSettings', {
    method: 'POST',
    data,
  });
}

export const getStickies = (patientId: number): Promise<StickyDTO[]> => (
  request<StickyDTO[]>(
    '/sticky/getAllStickiesByPatientId',
    { method: 'GET', params: { patientId } },
  )
);

export const getInsurancesByPatientId = (patientId: number): Promise<PatientDTO> => (
  request<PatientDTO>(
    `/patient/GetPatientInsurancesById/${patientId}`,
    { method: 'GET' },
  )
);

export const getOrgBanner = (orgId: number): Promise<MultiMediaResponseDTO> => (
  request<MultiMediaResponseDTO>(
    `/multimedia/GetOrgMultiMediaByCategory/${orgId}/BannerPicture`,
    { method: 'GET' },
  )
);

export const checkEligibility = (data: EligibilityRequestDTO): Promise<EligibilityResponseDTO> => (
  request<EligibilityResponseDTO>('/CH/checkEligibility', {
    method: 'POST',
    data,
  })
);

export const getInsuranceListByText = (search: string = ''): Promise<string[]> => (
  request<PayerDTO[]>('/ch/searchInsurance', {
    method: 'GET',
    params: { search },
  })
    .then((response: PayerDTO[] = []) => compact(map(response ?? [], 'payerName')))
);
