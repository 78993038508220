import React, { forwardRef } from 'react';
import type {
  ForwardedRef,
  MouseEvent,
  ReactNode,
} from 'react';
import clsx from 'clsx';
import MuiButton from '@mui/material/Button';

export type Props = {
  className?: string;
  type?: 'button' | 'submit';
  variant: 'text' | 'outlined' | 'contained';
  color?: 'inherit' | 'primary' | 'secondary';
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  disabled?: boolean;
  href?: string;
  children: ReactNode;
};

const Button = forwardRef(({
  className,
  type = 'button',
  variant,
  color = 'inherit',
  onClick,
  onFocus,
  onBlur,
  disabled,
  href,
  children,
}: Props, ref: ForwardedRef<HTMLButtonElement>) => {
  const btnClasses = {
    text: 'btn-text',
    outlined: 'btn-outlined',
    contained: 'btn-contained',
  };
  const btnColors: Record<string, 'primary' | 'secondary'> = {
    primary: 'secondary',
    secondary: 'primary',
  };
  const classes = clsx(
    'btn',
    className,
    btnClasses[variant],
  );

  return (
    <MuiButton
      className={classes}
      type={type}
      variant={variant}
      color={btnColors[color] || 'inherit'}
      onClick={onClick}
      disabled={disabled}
      onFocus={onFocus}
      onBlur={onBlur}
      href={href}
      ref={ref}
    >
      {children}
    </MuiButton>
  );
});

export default Button;
