import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';

export default function AIIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2 5.08571C2 4.00561 2 3.46556 2.21799 3.05302C2.40973 2.69013 2.71569 2.3951 3.09202 2.2102C3.51984 2 4.0799 2 5.2 2H10.8C11.9201 2 12.4802 2 12.908 2.2102C13.2843 2.3951 13.5903 2.69013 13.782 3.05302C14 3.46556 14 4.00561 14 5.08571V8.75C14 9.6486 14 10.0979 13.8478 10.4523C13.6448 10.9249 13.2554 11.3003 12.7654 11.4961C12.3978 11.6429 11.9319 11.6429 11 11.6429C10.6743 11.6429 10.5114 11.6429 10.3603 11.6773C10.1589 11.7231 9.97126 11.8136 9.81234 11.9415C9.69315 12.0374 9.59543 12.163 9.4 12.4143L8.42667 13.6657C8.28192 13.8518 8.20955 13.9449 8.12082 13.9781C8.04311 14.0073 7.95689 14.0073 7.87918 13.9781C7.79045 13.9449 7.71808 13.8518 7.57333 13.6657L6.6 12.4143C6.40457 12.163 6.30685 12.0374 6.18766 11.9415C6.02874 11.8136 5.84113 11.7231 5.63967 11.6773C5.48858 11.6429 5.32572 11.6429 5 11.6429C4.06812 11.6429 3.60218 11.6429 3.23463 11.4961C2.74458 11.3003 2.35523 10.9249 2.15224 10.4523C2 10.0979 2 9.6486 2 8.75V5.08571Z"
        fill="none"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.53 9.1C5.44333 9.1 5.36667 9.07 5.3 9.01C5.23333 8.95 5.2 8.87 5.2 8.77C5.2 8.73667 5.20667 8.69333 5.22 8.64L6.47 5.25C6.51667 5.13667 6.58667 5.05 6.68 4.99C6.77333 4.93 6.87333 4.9 6.98 4.9C7.08667 4.9 7.18667 4.93 7.28 4.99C7.38 5.05 7.45333 5.13667 7.5 5.25L8.83 8.64C8.84333 8.69333 8.85 8.73667 8.85 8.77C8.85 8.87 8.81667 8.95 8.75 9.01C8.68333 9.07 8.60667 9.1 8.52 9.1C8.46 9.1 8.39667 9.08333 8.33 9.05C8.27 9.02333 8.22333 8.97667 8.19 8.91L7.86 8.03C7.85333 7.99 7.82667 7.97 7.78 7.97H6.25C6.20333 7.97 6.17333 7.99 6.16 8.03L5.86 8.91C5.82667 8.97667 5.77667 9.02333 5.71 9.05C5.65 9.08333 5.59 9.1 5.53 9.1ZM6.55 7.37H7.45C7.49 7.37 7.52 7.35667 7.54 7.33C7.56667 7.29667 7.57333 7.25667 7.56 7.21L7.08 5.84C7.06 5.77333 7.03 5.74 6.99 5.74C6.94333 5.74 6.91 5.77333 6.89 5.84L6.44 7.22C6.42667 7.26 6.43 7.29667 6.45 7.33C6.47667 7.35667 6.51 7.37 6.55 7.37ZM10.3027 9.1C10.2161 9.1 10.1361 9.07 10.0627 9.01C9.9894 8.95667 9.95273 8.87667 9.95273 8.77V5.28C9.95273 5.16667 9.9894 5.08333 10.0627 5.03C10.1361 4.97667 10.2161 4.95 10.3027 4.95C10.3961 4.95 10.4761 4.97667 10.5427 5.03C10.6161 5.08333 10.6527 5.16667 10.6527 5.28V8.77C10.6527 8.87667 10.6161 8.95667 10.5427 9.01C10.4761 9.07 10.3961 9.1 10.3027 9.1Z"
        fill="currentColor"
        stroke="none"
      />
    </SvgIcon>
  );
}
