import { PatientDTO } from 'dtos';
import { formatDate } from 'utils/date';
import { getFullName } from 'utils/string';
import { PatientCompact } from 'pages/Dashboard/pages/Encounters/types/patient';

type AdditionalProps = Pick<PatientCompact, 'dateOfBirth' | 'formattedDateOfBirth' | 'fullName'>;
export const formatPatient = (patient: PatientDTO): PatientDTO & AdditionalProps => ({
  ...patient,
  dateOfBirth: formatDate(patient?.dateOfBirth ?? ''),
  formattedDateOfBirth: formatDate(patient?.dateOfBirth ?? ''),
  fullName: getFullName(patient),
});
