import { useQuery, UseQueryResult } from 'react-query';
import { PrefetchedDataProps } from 'pages/Dashboard/types/common';

export default function usePrefetchedData<T>(
  props: PrefetchedDataProps<T>,
): UseQueryResult<T, Error> {
  const { key, fetchFn, enabled } = props;
  return useQuery<T, Error>(
    key,
    fetchFn,
    { cacheTime: Infinity, enabled },
  );
}
