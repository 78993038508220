import {
  ComponentType,
  MouseEvent,
} from 'react';
import { RouteObject } from 'react-router-dom';
import {
  CalendarIcon,
  FolderIcon,
  HomeIcon,
  TaskIcon,
  BillingIcon,
} from 'pages/Dashboard/assets/icons';
import NotificationIndicator from 'pages/Dashboard/pages/Notifications/NotificationIndicator';
import { encountersRoutes } from 'pages/Dashboard/pages/Encounters/routes';
import { chartsRoutes } from 'pages/Dashboard/pages/Charts/routes';
import { appointmentRoutes } from 'pages/Dashboard/pages/Appointments/routes';
import { billingRoutes } from 'pages/Dashboard/pages/Billing/routes';

type Route = {
  name: string;
  title?: string;
  href?: string;
  className?: string;
  Icon?: ComponentType;
  Component?: ComponentType;
  routes?: Route[];
  subRoutes?: RouteObject[];
  onClick?: (e: MouseEvent<HTMLAnchorElement>) => void;
};

const dashboardRoutes: Route[] = [
  {
    name: 'Patients',
    routes: [
      {
        Icon: HomeIcon,
        name: 'Encounters',
        href: 'encounters',
        subRoutes: encountersRoutes,
      },
      {
        Icon: FolderIcon,
        name: 'Charts',
        href: 'charts',
        subRoutes: chartsRoutes,
      },
    ],
  },
  {
    name: 'Office',
    routes: [
      {
        Icon: CalendarIcon,
        name: 'Appointments',
        href: 'appointments',
        subRoutes: appointmentRoutes,
      },
      {
        Icon: TaskIcon,
        name: 'Todos',
        title: 'To-dos',
        href: 'todos',
      },
      {
        Icon: BillingIcon,
        name: 'Billing',
        href: 'billing',
        subRoutes: billingRoutes,
      },
    ],
  },
  {
    name: 'Other',
    className: '!mb-0.5',
    routes: [
      {
        Component: NotificationIndicator,
        name: 'Notifications',
        href: 'notifications',
      },
    ],
  },
];

export enum MediaCategory {
  Xray = 'Xray',
  OtherProviderNote = 'OtherProviderNote',
  InsuranceCard = 'InsuranceCard',
  LabReport = 'LabReport',
  MedicalRecords = 'MedicalRecords',
  VisitNoteImage = 'VisitNoteImage',
  ProfilePicture = 'ProfilePicture',
  FinalizedVisitNote = 'FinalizedVisitNote',
  Identification = 'Identification',
  ConsentForms = 'ConsentForms',
  MedicalNotes = 'MedicalNotes',
  RadiologyImages = 'RadiologyImages',
  EOB = 'EOBDoc',
}

export enum MediaOriginatorType {
  Org = 'Org',
  Patient = 'Patient',
  Billing = 'Billing',
}

const imagesTypes = [
  {
    label: 'MRI',
    value: 'MRI',
  },
  {
    label: 'X - Ray',
    value: 'Xray',
  },
  {
    label: 'CT Scan',
    value: 'CTScan',
  },
  {
    label: 'Ultrasound',
    value: 'Ultrasound',
  },
];

const reportTypes = [
  {
    label: 'Lab Report',
    value: 'LabReport',
  },
  {
    label: 'EMG',
    value: 'EMG',
  },
  {
    label: 'Pathology',
    value: 'Pathology',
  },
  {
    label: 'Operative Report',
    value: 'OperativeReport',
  },
];

const legalDocsTypes = [
  {
    label: 'Personal Injury Paperwork',
    value: 'PersonalInjuryPaperwork',
  },
  {
    label: 'Worker`s Compensation Paperwork',
    value: 'WorkersCompensationPaperwork',
  },
  {
    label: 'Car accident Paperwork',
    value: 'CarAccidentPaperwork',
  },
];

export {
  dashboardRoutes,
  imagesTypes,
  legalDocsTypes,
  reportTypes,
};
