import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { environment, sentryDSN } from 'env';

Sentry.init({
  dsn: sentryDSN,
  integrations: [new BrowserTracing()],
  tracesSampleRate: 1.0,
  environment,
  enabled: environment === 'prod',
});
