import React, {
  createContext,
  useState,
  useMemo, useCallback,
} from 'react';
import noop from 'lodash/noop';
import { CommonComponentPropType } from 'pages/Dashboard/types/common';

type ExpandableContextProps = {
  isExpanded: (id: number) => boolean;
  toggle: (id: number) => void;
};

export const ExpandableContext = createContext<ExpandableContextProps>({
  isExpanded: () => false,
  toggle: noop,
});

export default function ExpandableComponentProvider({ children }: CommonComponentPropType) {
  const [expanded, setExpanded] = useState<Set<number>>(new Set());

  const isExpanded = useCallback((id: number) => expanded.has(id), [expanded]);

  const toggle = useCallback((id: number) => {
    setExpanded((prevExpanded) => {
      if (prevExpanded.has(id)) {
        prevExpanded.delete(id);
      } else {
        prevExpanded.add(id);
      }
      return new Set(prevExpanded);
    });
  }, []);

  const value = useMemo(() => ({
    isExpanded,
    toggle,
  }), [isExpanded, toggle]);

  return (
    <ExpandableContext.Provider value={value}>
      {children}
    </ExpandableContext.Provider>
  );
}
