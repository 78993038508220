import React, { lazy } from 'react';
import { Navigate, RouteObject } from 'react-router-dom';
import { lazyLoadComponent } from 'utils/router';

export const encountersRoutes: RouteObject[] = [{
  path: '/encounters/:id',
  element: lazyLoadComponent(
    lazy(() => import('./pages/VisitNote')),
  ),
},
{
  path: '/encounters',
  element: lazyLoadComponent(
    lazy(() => import('./pages/List')),
  ),
},
{
  path: '*',
  element: <Navigate to="/encounters" />,
}];
