import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';

export default function MicIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      {...props}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3 7.479C3 10.2054 5.20952 12.4149 7.93587 12.4149C10.6622 12.4149 12.8724 10.2054 12.8724 7.479"
        stroke="currentColor"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.9366 14.2887V12.415"
        stroke="currentColor"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.02036 9.91365H7.85211C6.50481 9.91365 5.41211 8.82159 5.41211 7.47365V4.44C5.41211 3.0927 6.50481 2 7.85211 2H8.02036C9.3683 2 10.4604 3.0927 10.4604 4.44V7.47365C10.4604 8.82159 9.3683 9.91365 8.02036 9.91365Z"
        fillRule="evenodd"
        clipRule="evenodd"
        fill="currentColor"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.19141 4.77547H10.46"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.55664 6.88973H10.4601"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
}
