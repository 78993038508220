import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';

export default function PinIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      {...props}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.58574 2.79631C4.38972 2.30625 4.2917 2.06123 4.33258 1.86453C4.36832 1.69252 4.47052 1.54157 4.61695 1.4445C4.7844 1.3335 5.0483 1.3335 5.57611 1.3335H10.4251C10.9529 1.3335 11.2168 1.3335 11.3843 1.4445C11.5307 1.54157 11.6329 1.69252 11.6686 1.86453C11.7095 2.06123 11.6115 2.30625 11.4155 2.79631L10.7436 4.4761C10.7151 4.54736 10.7008 4.58299 10.6908 4.61974C10.6819 4.65239 10.6755 4.68566 10.6716 4.71928C10.6673 4.75713 10.6673 4.7955 10.6673 4.87225V6.29266C10.6673 6.43134 10.6673 6.50068 10.6809 6.567C10.693 6.62585 10.7129 6.68279 10.7403 6.73628C10.7711 6.79657 10.8144 6.85071 10.901 6.959L11.9475 8.26715C12.3914 8.82194 12.6133 9.09933 12.6135 9.33278C12.6137 9.5358 12.5214 9.72787 12.3628 9.85452C12.1803 10.0002 11.8251 10.0002 11.1146 10.0002H4.88661C4.17615 10.0002 3.82091 10.0002 3.63846 9.85452C3.47979 9.72787 3.38748 9.5358 3.3877 9.33278C3.38795 9.09933 3.60986 8.82194 4.05369 8.26715L5.10021 6.959C5.18684 6.85072 5.23015 6.79657 5.26096 6.73628C5.28828 6.68279 5.30826 6.62585 5.32033 6.567C5.33395 6.50068 5.33395 6.43134 5.33395 6.29266V4.87225C5.33395 4.7955 5.33395 4.75713 5.32961 4.71928C5.32575 4.68566 5.31934 4.65239 5.31044 4.61974C5.30041 4.58299 5.28616 4.54736 5.25765 4.4761L4.58574 2.79631Z"
        fill="currentColor"
      />
      <path
        d="M8.00061 10.0002L8.00061 14.6668M5.33395 4.87225V6.29266C5.33395 6.43134 5.33395 6.50068 5.32033 6.567C5.30826 6.62585 5.28828 6.68279 5.26096 6.73628C5.23015 6.79657 5.18684 6.85072 5.10021 6.959L4.05369 8.26715C3.60986 8.82194 3.38795 9.09933 3.3877 9.33278C3.38748 9.5358 3.47979 9.72787 3.63846 9.85452C3.82091 10.0002 4.17615 10.0002 4.88661 10.0002H11.1146C11.8251 10.0002 12.1803 10.0002 12.3628 9.85452C12.5214 9.72787 12.6137 9.5358 12.6135 9.33278C12.6133 9.09933 12.3914 8.82194 11.9475 8.26715L10.901 6.959C10.8144 6.85071 10.7711 6.79657 10.7403 6.73628C10.7129 6.68279 10.693 6.62585 10.6809 6.567C10.6673 6.50068 10.6673 6.43134 10.6673 6.29266V4.87225C10.6673 4.7955 10.6673 4.75713 10.6716 4.71928C10.6755 4.68566 10.6819 4.65239 10.6908 4.61974C10.7008 4.58299 10.7151 4.54736 10.7436 4.4761L11.4155 2.79631C11.6115 2.30625 11.7095 2.06123 11.6686 1.86453C11.6329 1.69252 11.5307 1.54157 11.3843 1.4445C11.2168 1.3335 10.9529 1.3335 10.4251 1.3335H5.57611C5.0483 1.3335 4.7844 1.3335 4.61695 1.4445C4.47052 1.54157 4.36832 1.69252 4.33258 1.86453C4.2917 2.06123 4.38972 2.30625 4.58574 2.79631L5.25765 4.4761C5.28616 4.54736 5.30041 4.58299 5.31044 4.61974C5.31934 4.65239 5.32575 4.68566 5.32961 4.71928C5.33395 4.75713 5.33395 4.7955 5.33395 4.87225Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
}
