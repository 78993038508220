import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';

export default function ImproveIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      {...props}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        stroke="#BFBFBF"
        strokeWidth="1.5"
        d="M11 1L14.6 4.6C17 -1.7 23.7 5 17.4 7.4L21 11L17.4 14.6C15 8.3 8.3 15 14.6 17.4L11 21L7.4 17.4C5 23.7 -1.7 17 4.6 14.6L1 11L4.6 7.4C7 13.7 13.7 7 7.4 4.6L11 1Z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
}
