import {
  useQuery,
  UseQueryResult,
} from 'react-query';
import useUserInfo from 'pages/Dashboard/hooks/useUserInfo';
import { DosespotNotifications } from 'pages/Dashboard/types/dosespot';
import { getDosespotNotifications } from 'pages/Dashboard/services/api';

export default function useDosespotNotifications(): UseQueryResult<
  DosespotNotifications, Error> {
  const userInfo = useUserInfo();

  return useQuery<DosespotNotifications, Error>(
    'dosespot-notiifications',
    getDosespotNotifications,
    {
      enabled: userInfo?.flags?.haseRxCredentials ?? false,
    },
  );
}
