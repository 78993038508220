import dayjs from 'dayjs';
import RelativeTime from 'dayjs/plugin/relativeTime';
import IsBetween from 'dayjs/plugin/isBetween';

/// @INFO: add more plugins if needed here
dayjs.extend(RelativeTime);
dayjs.extend(IsBetween);
// dayjs.extend(...); /* another plugin *

export default dayjs;
export { Dayjs } from 'dayjs';
