import React, {
  lazy,
} from 'react';
import {
  Navigate,
  RouteObject,
} from 'react-router-dom';
import { lazyLoadComponent } from 'utils/router';

export const billingRoutes: RouteObject[] = [{
  path: '/billing/charges',
  element: lazyLoadComponent(
    lazy(() => import('./Charges/index')),
  ),
}, {
  path: '/billing/charge-master',
  element: lazyLoadComponent(
    lazy(() => import('./ChargeMaster')),
  ),
}, {
  path: '/billing/payments',
  element: lazyLoadComponent(
    lazy(() => import('./Payments')),
  ),
}, {
  path: '/billing/eobs',
  element: lazyLoadComponent(
    lazy(() => import('./Payments/EOBsOverview')),
  ),
}, {
  path: '/billing',
  element: <Navigate to="/billing/charges" replace />,
}];
