import forIn from 'lodash/forIn';
import head from 'lodash/head';
import last from 'lodash/last';
import isNil from 'lodash/isNil';
import compact from 'lodash/compact';
import map from 'lodash/map';
import omit from 'lodash/omit';
import values from 'lodash/values';
import {
  formatPhone,
  getFullName,
} from 'utils/string';
import {
  formatDate,
  formatDateForAPI,
  formatDateTime,
  formatDateTimeOffset,
  getDateRangeString,
  getTimeRangeString,
} from 'utils/date';
import dayjs, {
  Dayjs,
} from 'utils/dayjs';
import {
  isEmptyString,
  setDefaultValueIf,
} from 'utils/misc';
import {
  Doctor,
  NormalizedUser,
} from 'pages/Dashboard/pages/Appointments/pages/List/types/event';
import { InsuranceFormInfo } from 'pages/Dashboard/pages/Appointments/pages/List/types/patient';
import { PatientCompact } from 'pages/Dashboard/pages/Encounters/types/patient';
import {
  AppointmentDTO,
  InsuranceDTO,
  BlockedTimeSlotDTO,
  ConfirmAppointmentCommuniqueDTO,
  PatientDTO,
  MultiMediaResponse,
  CalendarEventsResponseDTO,
  ModifyAppointmentRequestDTO,
  AddressDTO,
} from 'dtos';
import { CalendarEvent } from 'pages/Dashboard/pages/Appointments/pages/List/types/appointment';
import { getMultiMedia } from 'pages/Dashboard/services/api';
import {
  MediaCategory,
  MediaOriginatorType,
} from 'pages/Dashboard/utils/constants';
import keyBy from 'lodash/keyBy';
import { Patient } from 'pages/Dashboard/types/patient';

type RawEventType = AppointmentDTO & BlockedTimeSlotDTO

function normalizeEventDateAttributes(startDate: string, durationInMinutes: number = 0) {
  const start = dayjs(startDate);
  const end = dayjs(start).add(durationInMinutes, 'minutes');
  const eventDateRange = [start, end];

  return {
    start,
    end,
    eventDateRange,
    formattedStartDateTime: formatDateTime(start),
    formattedDateRange: getDateRangeString(start, end),
    formattedTimeRange: getTimeRangeString(start, end),
    durationInMinutes,
  };
}

function normalizePatientAttributes(patient: PatientDTO) {
  return {
    patient,
    formattedPatientPhoneNumber: formatPhone(patient?.phoneNumber?.phoneNumberWithAreaCode ?? ''),
    lastVisitDateTime: patient?.visitNotes?.length
      ? formatDateTime(last(patient?.visitNotes)?.visitDateTime ?? '') : null,
  };
}

export function normalizeIntakeFormStatus(
  confirmAppointmentCommunique: ConfirmAppointmentCommuniqueDTO | null = null,
) {
  if (isNil(confirmAppointmentCommunique)) return false;

  const onlySentFormKeys: string[] = [];
  forIn(confirmAppointmentCommunique?.sendForms ?? {}, (prop?: boolean, key?: string) => {
    if (prop) {
      onlySentFormKeys.push(`${key}Submitted`);
    }
  });

  const submissionStatuses = (
    confirmAppointmentCommunique?.intakeSubmissionStatus ?? {}
  ) as Record<string, boolean>;

  return onlySentFormKeys?.every((key: string) => submissionStatuses[key]) ?? true;
}

export function normalizeInsurance(insurance: InsuranceFormInfo): InsuranceDTO {
  const insuranceInfo = omit(insurance, ['insuranceOwnerDOB']);
  return {
    ...(insuranceInfo ?? {}),
    ...setDefaultValueIf(
      insuranceInfo ?? {},
      null,
      isEmptyString,
    ),
    isPrimary: insurance.isPrimary ?? false,
    insuranceOwnerDOB: formatDateForAPI(insurance?.insuranceOwnerDOB ?? dayjs()),
  };
}

export function normalizeAppointment({
  encounterId,
  userId,
  patient,
  patientAppointmentStatus,
  startDateTime,
  durationInMinutes,
  typeOfVisitDescription,
  reasonForVisit,
  canBeRescheduledSooner,
  location,
  confirmAppointmentCommunique,
  insuranceCopayCollection,
  officeLocation,
}: AppointmentDTO): CalendarEvent {
  return {
    id: `visit-${encounterId}`,
    objectId: encounterId!,
    userId: userId!,
    isTimeBlock: false,
    typeOfVisitDescription: typeOfVisitDescription ?? '',
    patientAppointmentStatus: patientAppointmentStatus ?? '',
    reason: reasonForVisit ?? '',
    canBeRescheduledSooner,
    location,
    ...normalizeEventDateAttributes(startDateTime!, durationInMinutes),
    ...normalizePatientAttributes(patient!),
    confirmAppointmentCommunique,
    intakeFormCompleted: normalizeIntakeFormStatus(confirmAppointmentCommunique),
    insuranceCopayCollection,
    officeLocation,
  };
}

export function normalizeTimeBlock({
  blockedTimeSlotId,
  userId,
  startDateTime,
  durationInMinutes,
  reasonForBlock,
}: BlockedTimeSlotDTO): CalendarEvent {
  return {
    id: `blockedTime-${blockedTimeSlotId}`,
    objectId: blockedTimeSlotId!,
    userId: userId!,
    isTimeBlock: true,
    typeOfVisitDescription: 'Time Blocked',
    reason: reasonForBlock ?? '',
    ...normalizeEventDateAttributes(startDateTime!, durationInMinutes),
  };
}

export function normalizeEvent(event: RawEventType): CalendarEvent {
  return isNil(event?.typeOfVisitDescription)
    ? normalizeTimeBlock(event as BlockedTimeSlotDTO)
    : normalizeAppointment(event as AppointmentDTO);
}

export function normalizeEvents(eventsDictionary: CalendarEventsResponseDTO): CalendarEvent[] {
  const { appointments, blockedTimeSlots } = eventsDictionary;
  return (appointments ?? [])
    .concat((blockedTimeSlots ?? []))
    .map(normalizeEvent);
}

export function serializeAppointmentForUpdate({
  objectId,
  start,
  durationInMinutes,
  typeOfVisitDescription,
  userId,
  patientAppointmentStatus,
  insuranceCopayCollection,
  location,
  reason: reasonForVisit,
  officeLocation,
}: Partial<CalendarEvent>): ModifyAppointmentRequestDTO {
  return {
    encounterId: objectId,
    startDateTime: !isNil(start) ? formatDateTimeOffset(start) : undefined,
    location,
    reasonForVisit,
    durationInMinutes,
    typeOfVisitDescription,
    patientAppointmentStatus,
    userId,
    insuranceCopayCollection,
    officeLocationId: officeLocation?.addressId,
  };
}

export function serializeTimeBlock({
  objectId,
  start,
  durationInMinutes,
  userId,
  reason: reasonForBlock,
}: Partial<CalendarEvent>): BlockedTimeSlotDTO {
  return {
    blockedTimeSlotId: objectId,
    startDateTime: !isNil(start) ? formatDateTimeOffset(start) : undefined,
    durationInMinutes,
    userId,
    reasonForBlock,
  };
}

export const getSortOptions = (sortField: string) => {
  const sortParts = sortField.split('_');
  const sortOrder = sortParts[0];
  const sortBy = sortParts.slice(1).join('_');
  return [sortOrder, sortBy];
};

const EMAIL_REGEX = /^[\w.-]+@[\w-]+(\.\w{2,})+$/i;
export const PATIENT_NAME_REGEX = /^[^,]*$/;

export const emailValidation = (email: string): boolean => EMAIL_REGEX.test(email);
export const phoneValidation = (phone?: string | null): boolean => /^\+\d{11}$/i.test(phone ?? '');
export const validateEmailIfSet = (email: string = '') => (email ?? '').trim().length === 0 || emailValidation(email);
export const validatePhoneIfSet = (phone: string | null = '') => (phone ?? '').substring(2).length === 0 || phoneValidation(phone ?? '');
export const validateDateRange = (dateRange: Dayjs[]) => (
  dateRange[0]?.isBefore(dateRange[1]) ?? false
);
export const validateDateIfFuture = (date: Dayjs) => isNil(date) || !date.isAfter(formatDate(dayjs()), 'date');

export function validateAddress(address: AddressDTO) {
  return !values(omit(address, ['addressId', 'unit', 'zipCode', 'taxId', 'nickName'])).some(isEmptyString)
    && /^\d{5}$/i.test(address?.zipCode ?? '');
}

export function validateInsurance(insurance: InsuranceDTO) {
  const omitKeys = [
    'insuranceId',
    'insuranceOwnerId',
    'insuranceOwnerAddress',
    'insuranceOwnerAddressId',
    'insuranceOwnerMiddleName',
    'groupName',
    'groupNumber',
    'coverageLevel',
    'coverageType',
    'effectiveFrom',
    'copay',
    'insuranceOwnerPhoneNumber',
    'isPrimary',
  ];

  if (!/compensation$/i.test(insurance?.insuranceOwnerType ?? '')) {
    omitKeys.push('employerName');
  }

  return validateAddress(insurance?.insuranceOwnerAddress ?? {})
    && phoneValidation(insurance?.insuranceOwnerPhoneNumber ?? '--')
    && !values(omit(insurance, omitKeys)).some(isEmptyString);
}

function getFinalizedNoteCaption(fullName: string, fileName: string = '') {
  const [, date] = fileName.match(/^\d+-visit-(\d{4}-\d{2}-\d{2})/i) ?? [];
  return typeof date !== 'undefined' ? `${fullName} Visit on ${formatDate(date)}` : undefined;
}

export const normalizeFinalizedVisitNotes = (
  media: MultiMediaResponse[],
  patient: PatientCompact,
) => {
  const fullName = getFullName(patient);
  return media.map((item) => ({
    ...item,
    caption: getFinalizedNoteCaption(fullName, item.fileName ?? ''),
  }));
};

export const compactPatient = ({
  patientId,
  dateOfBirth,
  firstName,
  lastName,
  middleName,
  genderDescription,
  address,
  phoneNumber,
}: PatientDTO): PatientCompact => ({
  patientId,
  dateOfBirth: dateOfBirth ?? '',
  formattedDateOfBirth: formatDate(dateOfBirth ?? ''),
  fullName: getFullName({ firstName, lastName, middleName }),
  firstName: firstName ?? '',
  lastName: lastName ?? '',
  middleName: middleName ?? '',
  genderDescription: genderDescription ?? '--',
  address,
  phoneNumber: phoneNumber?.phoneNumberWithAreaCode ?? '--',
});

export const normalizeDoctors = (doctors: Doctor[]): NormalizedUser[] => (
  doctors.map((doctor: Doctor) => ({
    ...doctor,
    id: doctor.userId,
    name: getFullName(doctor),
  }))
);

export const getPatientProfileImages = (ids: (number | undefined)[] = [])
  : Promise<MultiMediaResponse[]> => {
  const compacted = compact(ids);

  return compacted.length === 0
    ? Promise.resolve([])
    : getMultiMedia({
      generateUrls: true,
      list: compacted
        .map((id) => ({
          originatorId: id,
          originatorType: MediaOriginatorType.Patient,
          mediaCategory: MediaCategory.ProfilePicture,
        })),
    });
};

export const withProfileImages = async (
  _patients: PatientDTO[] | PatientDTO,
): Promise<Patient[] | Patient> => {
  const isList = Array.isArray(_patients);
  const patients = (isList ? _patients : [_patients]) as Patient[];
  const ids = map(patients, 'patientId');

  const profileImages = await getPatientProfileImages(ids);
  const profileImagesMap = keyBy(profileImages, 'originatorId');

  patients.forEach((patient) => {
    patient.profileImage = profileImagesMap[patient?.patientId ?? 0];
  });

  return isList ? patients : head(patients) ?? {};
};
