import isNil from 'lodash/isNil';
import unescape from 'lodash/unescape';
import forEach from 'lodash/forEach';
import values from 'lodash/values';
import { MultiMediaResponse } from 'dtos';
import dayjs from 'utils/dayjs';
import { pluralize } from 'utils/string';
import { AxiosError } from 'axios';

export { v4 as uuid } from 'uuid';

export const em = (em: number = 1, relativeSize: number = 16) => em * relativeSize;

export function blobToDataUrl(blob: Blob | File | null): Promise<string | null> {
  return isNil(blob) ? Promise.resolve(null) : new Promise((resolve) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      resolve(reader.result as string);
    };
    reader.readAsDataURL(blob);
  });
}

export function getFileExtension(fileName: string = ''): string | null {
  const [_, ext] = fileName.match(/^.*\.(\w+)$/) ?? [null, null];
  return ext ? ext.toLowerCase() : null;
}

export function getMediaType(media: MultiMediaResponse): 'document' | 'image' {
  return getFileExtension(media.fileName ?? '') === 'pdf' ? 'document' : 'image';
}

export function getDefaultIf(
  value: any,
  defaultValue: typeof value,
  condition: (v: typeof value) => boolean,
) {
  return condition(value) ? defaultValue : value;
}

export function setDefaultValueIf(
  object: Record<string, any>,
  defaultValue: any,
  condition: (v: any, key: string) => boolean,
) {
  const result: Record<string, any> = {};

  forEach(object, (v, k) => {
    if (condition(v, k)) {
      result[k] = defaultValue;
    }
  });

  return result;
}

export function isEmptyString(value: any) {
  return isNil(value) || (typeof value === 'string' && value.trim() === '');
}

export function unescapeHtml(content: string, currentDate: string) {
  return unescape(content).replaceAll('{Date}', `<b>${currentDate}</b>`);
}

export function getSubmitButtonText(isNew: boolean, isSubmitting: boolean) {
  return [
    isNew ? 'Creat' : 'Updat', // start
    isSubmitting ? 'ing...' : 'e', // end
  ].join('');
}

type DateUnits = 'year' | 'month' | 'week' | 'day';
const dateUnits: DateUnits[] = ['year', 'month', 'week', 'day'];
export function getPatientAge(dateOfBirth?: string): string {
  let value = 0;
  let currentUnitIndex = -1;
  while (value === 0 && currentUnitIndex < 3) {
    currentUnitIndex += 1;
    value = dayjs().diff(dateOfBirth, dateUnits[currentUnitIndex]);
  }
  return `${pluralize(dateUnits[currentUnitIndex], value)} old`;
}

type CommonErrorDataResponse = {
  errors: Record<string, string[]>;
};

type ErrorResponse = {
  Message: string;
};

export function getErrorMessage(error: AxiosError): string | null {
  const data = error?.response?.data;
  const errorMessage = typeof data === 'object'
    ? (
      (data as ErrorResponse)?.Message
      ?? values((data as CommonErrorDataResponse)?.errors)[0]
    ) : null;

  const responseMessage = typeof data === 'string' ? data as string : errorMessage;
  const statusText = error?.response?.statusText === ''
    ? null : error?.response?.statusText;

  return responseMessage === ''
    ? error?.message ?? statusText ?? 'Oops, something went wrong!'
    : responseMessage;
}
