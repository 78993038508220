import { lazy } from 'react';
import { lazyLoadComponent } from 'utils/router';
import { RouteObject } from 'react-router-dom';

export const appointmentRoutes: RouteObject[] = [{
  path: '/appointments/',
  element: lazyLoadComponent(
    lazy(() => import('./pages/List')),
  ),
}];
