import React, { lazy } from 'react';
import {
  createBrowserRouter,
  Navigate,
  Outlet,
} from 'react-router-dom';
import ProtectedRouter from 'pages/Auth/ProtectedRouter';
import AuthorizeScreen from 'pages/Auth/authorize';
import { lazyLoadComponent } from 'utils/router';
import { dashboardRoutes } from 'pages/Dashboard/utils/constants';
import ErrorBoundary from 'pages/Dashboard/components/ErrorBoundary';

const routes = dashboardRoutes.flatMap((route) => route.routes);

function ErrorBoundaryLayout() {
  return (
    <ErrorBoundary>
      <Outlet />
    </ErrorBoundary>
  );
}

export const router = createBrowserRouter([{
  element: <ErrorBoundaryLayout />,
  children: [{
    path: '/',
    element: (
      <ProtectedRouter>
        {lazyLoadComponent(
          lazy(() => import('pages/Dashboard')),
        )}
      </ProtectedRouter>
    ),
    children: [
      ...routes.map((route) => ({
        path: `/${route?.href}`,
        element: lazyLoadComponent(lazy(
          () => import(`pages/Dashboard/pages/${route?.name}`),
        )),
        ...(route?.subRoutes ? { children: route.subRoutes } : {}),
      })),
      {
        path: '/profile',
        element: lazyLoadComponent(lazy(
          () => import('pages/Dashboard/pages/Profile'),
        )),
      },
      {
        path: '/',
        element: <Navigate to="/encounters" />,
      },
      {
        path: '*',
        element: <Navigate to="/encounters" />,
      },
    ],
  },
  {
    path: '/authorize',
    element: <AuthorizeScreen />,
  },
  {
    path: '*',
    element: <Navigate to="/" />,
  }],
}]);
